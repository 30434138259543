import * as styles from "./slideout.module.scss"

import React, { useEffect, useState } from "react"

import Button from "./button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"

const Slideout = ({ className, isOpen, onClose, children }) => {
  const classes = [styles.slideout]
  isOpen && classes.push(styles.active)
  className && classes.push(className)

  const [open] = useState(isOpen)

  useEffect(() => {
    const html = document.querySelector('html')
    open ? (html.style.overflow = 'hidden') : (html.style.overflow = 'visible')
    return () => html.style.overflow = 'visible'
  }, [open])

  return (
    <div className={ classes.join(' ') }>
      <div className={ styles.overlay } onClick={ onClose } onKeyPress={ onClose } tabIndex="-1" role="button" aria-label="close slideout"></div>
      <div className={ styles.content }>
        {/* <button aria-label="close modal" onClick={ onClose }><span>&nbsp;</span></button> */}
        <Button theme="ghost" size="large" type="button" onClick={ onClose }><FontAwesomeIcon icon={ faTimes } /></Button>
        <div>{ children }</div>
      </div>
    </div>
  )
}

export default Slideout